<template>
  <div class="widget">
    <div class="block-title themed-background">
      <h4 class="text-white widget-content-light">Transaction Histories</h4>
    </div>
    <div
      class="table-responsive"
      style="
    max-height: 400px;
"
    >
      <table
        class="table table-vcenter table-bordered table-hover table-striped table-condensed"
        v-if="transactions.length > 0"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Action</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in transactions" :key="transaction.Money_ID">
            <td>{{ transaction.Money_ID }}</td>
            <td>{{ transaction.Money_Comment }}</td>
            <td>{{ toDateTime(transaction.Money_Time) }}</td>
          </tr>
        </tbody>
        <tr v-if="totalPage > 1">
          <td colspan="3" class="text-right">
            <paginate
              v-model="page"
              :page-count="totalPage"
              :click-handler="handlePagination"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            ></paginate>
          </td>
        </tr>
      </table>

      <div v-else class="block-section">No transaction history</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TransactionHistoryList",
  data: () => ({
    page: 1,
    totalPage: 1
  }),
  created() {},
  computed: {
    ...mapGetters({
      transactions: "dashboard/Transactions"
    })
  },
  methods: {}
};
</script>
