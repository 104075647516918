<template>
  <div>
    <div class="block-title themed-background">
      <h1>Login Histories</h1>
    </div>
    <div class="table-responsive" v-if="loginHistories && historyList" style="max-height: 400px">
      <table
        class="table table-vcenter table-bordered table-hover table-striped table-condensed"
        v-if="historyList.length > 0"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Login by IP</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="history in historyList" :key="history.id">
            <td>{{ history.id }}</td>
            <td>{{ history.ip }}</td>
          </tr>
        </tbody>
        <tr v-if="totalPage > 1">
          <td colspan="2" class="text-right">
            <paginate
              v-model="page"
              :page-count="totalPage"
              :click-handler="handlePagination"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            ></paginate>
          </td>
        </tr>
      </table>

      <div v-else class="block-section">No login history</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoginHistoryList",
  data: () => ({
    page: 1
  }),
  created() {},
  computed: {
    ...mapGetters({
      loginHistories: "dashboard/HistoryLogins"
    }),
    totalPage() {
      if (this.loginHistories && this.loginHistories.length > 0) {
        return Math.floor(this.loginHistories.length / 15) + 1;
      }
    },
    historyList() {
      if (this.loginHistories && this.loginHistories.length > 0) {
        return this.loginHistories.slice((this.page - 1) * 15, this.page * 15);
      }
    }
  },
  methods: {
    // async fetchLoginHistories(page) {
    //   this.$startLoading();
    //   try {
    //     const response = await dashboardAPI.list(page);
    //     this.loginHistories = response.loginHistory;
    //   } catch (err) {}
    //   this.$finishLoading();
    // },
    handlePagination(pageNum) {
      // this.fetchLoginHistories(pageNum);
    }
  }
};
</script>
