<template>
  <div>
    <div class="list-game">
      <div class="game">
        <div class="left-content">
          <div class="game-title">Casino SA</div>
          <div class="game-balance">Available: {{ Balance.Casino }}</div>
        </div>
        <div class="right-content">
          <div class="group-button">
            <button class="btn btn-success mx-2" @click="showDepositModal(1)">
              Deposit
            </button>
            <button class="btn btn-warning mx-2" @click="showWithdrawModal(1)">
              Withdraw
            </button>
            <button v-if="Game.Casino" class="btn btn-primary mx-2" @click="onPlayGame('CO')">
              Play
            </button>
          </div>
        </div>
      </div>
      <div class="game">
        <div class="left-content">
          <div class="game-title">Casino WM555</div>
          <div class="game-balance">Available: {{ Balance.WM555 }}</div>
        </div>
        <div class="right-content">
          <div class="group-button">
            <button
              v-if="GameStatus.WM555 == 1"
              class="btn btn-success mx-2"
              @click="depositWm555()"
            >
              Deposit
            </button>
            <button
              v-if="GameStatus.WM555 == 1"
              class="btn btn-warning mx-2"
              @click="withdrawWm555()"
            >
              Withdraw
            </button>
            <button
              v-if="GameStatus.WM555 != 1"
              class="btn btn-primary mx-2"
              @click="showregisterWm555Modal()"
            >
              Register
            </button>
            <span v-else>
              <button
                v-if="ChangePasswordStatus.WM555 == 1"
                class="btn btn-secondary mx-2"
                @click="showChangePassVM555()"
              >
                Change Password
              </button>
              <button class="btn btn-primary mx-2" @click="showPlayGameVM555()">
                Play
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="game">
        <div class="left-content">
          <div class="game-title">Slots Game</div>
          <div class="game-balance">Available: {{ Balance.SkyGame }}</div>
        </div>
        <div class="right-content">
          <div class="group-button">
            <button class="btn btn-success mx-2" @click="showDepositModal(4)">
              Deposit
            </button>
            <button class="btn btn-warning mx-2" @click="showWithdrawModal(4)">
              Withdraw
            </button>
            <button v-if="Game.SkyGame" class="btn btn-primary mx-2" @click="onPlayGame('SL')">
              Play
            </button>
          </div>
        </div>
      </div>
      <div class="game">
        <div class="left-content">
          <div class="game-title">Fish Shooter</div>
          <div class="game-balance">Available: {{ Balance.SkyGame }}</div>
        </div>
        <div class="right-content">
          <div class="group-button">
            <button class="btn btn-success mx-2" @click="showDepositModal(4)">
              Deposit
            </button>
            <button class="btn btn-warning mx-2" @click="showWithdrawModal(4)">
              Withdraw
            </button>
            <button v-if="Game.SkyGame" class="btn btn-primary mx-2" @click="onPlayGame('FS')">
              Play
            </button>
          </div>
        </div>
      </div>
      <div class="game">
        <div class="left-content">
          <div class="game-title">Sport Book</div>
          <div class="game-balance">Available: {{ Balance.SportBook }}</div>
        </div>
        <div class="right-content">
          <div class="group-button">
            <button class="btn btn-success mx-2" @click="showDepositModal(2)">
              Deposit
            </button>
            <button class="btn btn-warning mx-2" @click="showWithdrawModal(2)">
              Withdraw
            </button>
            <button v-if="Game.SportBook" class="btn btn-primary mx-2" @click="onPlayGame('SB')">
              Play
            </button>
          </div>
        </div>
      </div>
      <div class="game">
        <div class="left-content">
          <div class="game-title">Binary Options</div>
          <!-- <div class="game-balance">Available: {{ Balance.SportBook }} EUSD</div> -->
          <div class="game-balance text-danger">Coming soon!</div>
        </div>
        <div class="right-content">
          <div class="group-button">
            <!-- <button class="btn btn-success mx-2" @click="showDepositModal(2)">
              Deposit
            </button>
            <button class="btn btn-warning mx-2" @click="showWithdrawModal(2)">
              Withdraw
            </button> -->
            <button v-if="Game.SportBook" class="btn btn-primary mx-2" @click="onPlayGame('BO')">
              Play
            </button>
          </div>
        </div>
      </div>
    </div>
    <v-modal name="deposit" :width="480" :height="'auto'" :adaptive="true">
      <deposit-form :balanceType="balanceType"></deposit-form>
    </v-modal>
    <v-modal name="withdraw" :width="480" :height="'auto'" :adaptive="true">
      <withdraw-form :balanceType="balanceType"></withdraw-form>
    </v-modal>
    <v-modal name="changePassWm555" :width="480" :height="'auto'" :adaptive="true">
      <div class="title">
        Change Password WM555
      </div>
      <div class="row" style="padding:10px">
        <ValidationObserver v-slot="{ invalid }">
          <form class="form-horizontal" style="padding: 20px 20px 0px;">
            <ValidationProvider
              vid="password"
              rules="required|min:8|max:20"
              v-slot="{ errors }"
              tag="div"
            >
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-key"></i></span>
                    <input
                      v-model="changePass.password"
                      type="password"
                      name="login-password"
                      class="form-control input-lg"
                      placeholder="Password"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider
              vid="new_password"
              rules="required|min:8|max:20"
              v-slot="{ errors }"
              tag="div"
            >
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-key"></i></span>
                    <input
                      v-model="changePass.new_password"
                      type="password"
                      name="new_password"
                      class="form-control input-lg"
                      placeholder="New password"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider
              rules="required|confirmed:new_password"
              v-slot="{ errors }"
              tag="div"
            >
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-key"></i></span>
                    <input
                      v-model="changePass.confirm_password"
                      type="password"
                      name="login-password-confirmation"
                      class="form-control input-lg"
                      placeholder="Password confirmation"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
              <div class="form-group" style="padding:5px 20px 0">
                <p class="bg-warning " style="margin-bottom:0">
                  <b>Note:</b> Your password must be at least 8 digits and must be in uppercase and
                  lowercase letters as well as have at least 1 number.
                </p>
              </div>
            </ValidationProvider>
            <div class="form-group">
              <div class="col-xs-12 text-center">
                <button
                  type="button"
                  class="btn btn-sm btn-info"
                  style="min-width:120px"
                  :disabled="invalid"
                  @click="handleChangePasswordWM555"
                >
                  Change Password
                </button>
                <button
                  class="btn btn-danger mt-2"
                  @click="hideModalChangePassWM55"
                  style="margin-left:5px"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </v-modal>
    <v-modal name="registerWm555" :width="480" :height="'auto'" :adaptive="true">
      <div class="title">
        Register WM555
      </div>
      <div class="row" style="padding:10px">
        <ValidationObserver v-slot="{ invalid }">
          <form class="form-horizontal" style="padding: 20px 20px 0px;">
            <ValidationProvider>
              <div class="form-group">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-user"></i></span>
                    <input
                      type="text"
                      class="form-control input-lg"
                      placeholder="username"
                      :value="'now' + Info.id"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider
              vid="password"
              rules="required|min:8|max:20"
              v-slot="{ errors }"
              tag="div"
            >
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-key"></i></span>
                    <input
                      v-model="password"
                      type="password"
                      name="login-password"
                      class="form-control input-lg"
                      placeholder="Password"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-key"></i></span>
                    <input
                      v-model="passwordConfirm"
                      type="password"
                      name="login-password-confirmation"
                      class="form-control input-lg"
                      placeholder="Password confirmation"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
              <div class="form-group" style="padding:5px 20px 0">
                <p class="bg-warning " style="margin-bottom:0">
                  <b>Note:</b> Your password must be at least 8 digits and must be in uppercase and
                  lowercase letters as well as have at least 1 number.
                </p>
              </div>
            </ValidationProvider>
            <div class="form-group">
              <div class="col-xs-12 text-center">
                <button
                  type="button"
                  class="btn btn-sm btn-info"
                  style="min-width:120px"
                  :disabled="invalid"
                  @click="handleRegister"
                >
                  Register
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </v-modal>
    <v-modal name="playWm555" :width="480" :height="'auto'" :adaptive="true">
      <div class="title bg-warning">
        Note
      </div>
      <div class="" style="padding:20px;text-align:center">
        <p>
          <b style="color:red">Warning:</b> <br />When logging in Live Casino system, your username
          is <b>{{ "now" + Info.id }}</b
          >. <br />
          Your account password is the one you registered before.
        </p>
        <button class="btn btn-primary mt-2" @click="onPlayGame('VM555')">
          Play
        </button>
        <button class="btn btn-danger mt-2" @click="hideModal" style="margin-left:5px">
          Cancel
        </button>
      </div>
    </v-modal>
    <v-modal name="depositWm555" :width="480" :height="'auto'" :adaptive="true">
      <div class="title">
        Deposit WM555
      </div>
      <div class="row" style="padding:10px">
        <ValidationObserver v-slot="{ invalid }">
          <form class="form-horizontal" style="padding: 20px 20px 0px;">
            <ValidationProvider vid="number" rules="required" v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-usd"></i></span>
                    <input
                      v-model="deposit_amount"
                      type="number"
                      step="0.001"
                      min="50"
                      name="amount"
                      class="form-control input-lg"
                      placeholder="Enter amount"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <div class="form-group">
              <div class="col-xs-12 text-center">
                <button
                  type="button"
                  class="btn btn-sm btn-info"
                  style="min-width:120px"
                  :disabled="invalid"
                  @click="handleDepositWM555"
                >
                  Deposit
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </v-modal>
    <v-modal name="withdrawWm555" :width="480" :height="'auto'" :adaptive="true">
      <div class="title">
        Withdraw WM555
      </div>
      <div class="row" style="padding:10px">
        <ValidationObserver v-slot="{ invalid }">
          <form class="form-horizontal" style="padding: 20px 20px 0px;">
            <ValidationProvider vid="number" rules="required" v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-usd"></i></span>
                    <input
                      v-model="withdraw_amount"
                      type="number"
                      step="0.001"
                      min="100"
                      name="amount"
                      class="form-control input-lg"
                      placeholder="Enter amount"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </ValidationProvider>
            <div class="form-group">
              <div class="col-xs-12 text-center">
                <button
                  type="button"
                  class="btn btn-sm btn-info"
                  style="min-width:120px"
                  :disabled="invalid"
                  @click="handleWithdrawWM555"
                >
                  Withdraw
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </v-modal>
  </div>
</template>

<script>
import DepositForm from "@/components/subAccount/DepositForm";
import WithdrawForm from "@/components/subAccount/WithdrawForm";
import { mapGetters } from "vuex";
export default {
  components: {
    DepositForm,
    WithdrawForm
  },
  data: () => {
    return {
      balanceType: null,
      password: null,
      passwordConfirm: null,
      token: null,
      deposit_amount: 0,
      withdraw_amount: 0,
      changePass: {
        password: null,
        new_password: null,
        confirm_password: null
      }
    };
  },
  computed: {
    ...mapGetters({
      Balance: "dashboard/Balance",
      Game: "dashboard/Game",
      Info: "dashboard/Info",
      GameStatus: "dashboard/GameStatus",
      ChangePasswordStatus: "dashboard/ChangePasswordStatus"
    })
  },
  methods: {
    hideModalChangePassWM55() {
      this.$modal.hide("changePassWm555");
    },
    hideModal() {
      this.$modal.hide("playWm555");
    },
    withdrawWm555() {
      this.$modal.show("withdrawWm555");
    },
    depositWm555() {
      this.$modal.show("depositWm555");
    },
    showDepositModal(type) {
      this.balanceType = type;
      this.$modal.show("deposit");
    },
    showChangePassVM555() {
      this.$modal.show("changePassWm555");
    },
    showPlayGameVM555() {
      this.$modal.show("playWm555");
    },
    showregisterWm555Modal() {
      this.$modal.show("registerWm555");
    },
    showWithdrawModal(type) {
      this.balanceType = type;
      this.$modal.show("withdraw");
    },
    async handleWithdrawWM555() {
      this.$startLoading();
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("withdraw");
      this.token = token;
      this.$store.dispatch("wm555/req_withdrawWM555", {
        amount: this.withdraw_amount,
        token: this.token
      });
    },
    async handleDepositWM555() {
      this.$startLoading();
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("deposit");
      this.token = token;
      this.$store.dispatch("wm555/req_depositWM555", {
        amount: this.deposit_amount,
        token: this.token
      });
    },
    async handleChangePasswordWM555() {
      this.$startLoading();
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("deposit");
      this.token = token;
      this.$store.dispatch("wm555/req_changePasss", {
        password: this.changePass.password,
        new_password: this.changePass.new_password,
        confirm_password: this.changePass.confirm_password,
        token: this.token
      });
    },
    async handleRegister() {
      this.$startLoading();
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("deposit");
      this.token = token;
      this.$store.dispatch("wm555/req_register", {
        password_confirm: this.passwordConfirm,
        password: this.password,
        token: this.token
      });
    },
    onPlayGame(game) {
      switch (game) {
        case "SL": // Binary Option
          this.PlayGame("https://fish.123betnow.net?slot");
          break;
        case "CO": // Casino Online
          this.PlayGame("https://casino.123betnow.net");
          break;
        case "SB": // Sport Book
          this.PlayGame("https://sportbook.123betnow.net");
          break;
        case "FS":
          this.PlayGame("https://fish.123betnow.net?fish");
          break;
        case "BO":
          alert("Coming soon!");
          break;
        case "VM555":
          this.PlayGame(this.Game.WM555);
          break;
      }
    },
    PlayGame(url) {
      if (navigator.userAgent.indexOf("Win") != -1) {
        window.open(url);
      }
      if (navigator.userAgent.indexOf("Mac") != -1) {
        window.open(url);
      }
      if (navigator.userAgent.indexOf("Linux") != -1) {
        window.location.assign(url, "_blank");
      }
      if (navigator.userAgent.indexOf("Android") != -1) {
        window.location.assign(url, "_blank");
      }
      if (navigator.userAgent.indexOf("like Mac") != -1) {
        window.location.assign(url, "_blank");
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "wallet/DEPOSIT_SUCCESS":
          this.$modal.hide("deposit");
          break;
        case "wallet/WITHDRAW_SUCCESS":
          this.$modal.hide("withdraw");
          break;
        case "wm555/REGISTER_SUCCESS":
          this.$modal.hide("registerWm555");
          break;
        case "wm555/DEPOSIT_SUCCESS":
          this.$modal.hide("depositWm555");
          break;
        case "wm555/WITHDRAW_SUCCESS":
          this.$modal.hide("withdrawWm555");
          break;
        case "wm555/CHANGEPASSWORD_SUCCESS":
          this.$modal.hide("changePassWm555");
          this.changePass.password = null;
          this.changePass.new_password = null;
          this.changePass.confirm_password = null;
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>

<style>
.btn-secondary {
  background-color: #ec4a89;
  color: #fff;
}
.btn-secondary:hover {
  background-color: #d43a75;
  color: #fff;
}
.mx-2 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.bg-warning {
  background: #ffc107 !important;
  padding: 10px !important;
}
.list-game .game {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  justify-self: center;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.vm--container .vm--modal {
  background: #fff !important;
}
.list-game {
  background-color: rgb(255 255 255 / 25%);
  padding: 5px;
  border-radius: 5px;
  min-height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.right-content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  padding-right: 15px;
}
.right-content .group-button .btn {
  min-width: 90px;
}
.left-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  align-self: center;
  padding-left: 15px;
  flex-direction: column;
}

@media (max-width: 500px) {
  .list-game .game {
    flex-direction: column;
  }
}
.game-title {
  color: #ffa300;
  font-size: 20px;
  font-weight: 800;
}
.title {
  text-align: center;
  background: #1e90ff;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 700;
  color: white;
}
.game-balance {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}
</style>
