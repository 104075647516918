<template>
  <base-layout>
    <div class="row">
      <div class="col-sm-12">
        <statistic-panel class="mb-3"></statistic-panel>
      </div>
      <div class="col-lg-6 mb-5">
        <div class="box-game">
          <StatisticAgency></StatisticAgency>
        </div>
      </div>
      <div class=" mb-5 box-game  col-lg-6">
        <game-panel :key="`${new Date().getTime()}`"></game-panel>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="block mb-5">
          <transaction-histories></transaction-histories>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import StatisticPanel from "@/components/dashboard/StatisticPanel";
import TransactionHistories from "@/components/dashboard/TransactionHistoryList";
import LoginHistories from "@/components/dashboard/LoginHistoryList";
import IB from "@/components/dashboard/IB";
import GamePanel from "@/components/dashboard/GamePanel";
import BaseLayout from "./layouts/Base";
import StatisticAgency from "@/components/dashboard/StatisticAgency";

export default {
  name: "Dashboard",
  components: {
    BaseLayout,
    StatisticPanel,
    TransactionHistories,
    LoginHistories,
    GamePanel,
    StatisticAgency,
    IB
  },
  created() {
    // if (localStorage.getItem("access_token")) {
    this.$store.dispatch("dashboard/req_getDashboard", 1);
    this.$store.dispatch("dashboard/req_getAgency");
    // }
  }
};
</script>
<style scoped>
.mb-5 {
  margin-bottom: 2.5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
/* .box-game{
  max-width: 670px;
} */
</style>
<style>
.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
</style>
